<template>
  <div class="address-view">
    <el-button class="v-primary ml10" type="primary" @click="centerDialogVisible = true">添加地址</el-button>
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="address" align="center" label="地址" />
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status ? "启用" : "禁用" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                edit(scope.row, false);
                bEdit = true;
              "
              >详情</el-button
            >
            <customPopconfirm
              confirm-button-text="确定"
              k
              cancel-button-text="取消"
              @confirm="del(scope.row.addressId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button slot="reference" class="ml10" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="添加地址"
      :visible.sync="centerDialogVisible"
      @open="openDialog"
      @close="close"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px">
        <el-form-item label="公司地址" prop="address">
          <el-input id="suggestId" v-model="ruleForm.address"></el-input>
          <div id="baidumap"></div>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio
            class="mr10"
            style="margin: 0"
            v-for="(item, index) in radioStatusArr"
            :key="index"
            v-model="ruleForm.status"
            :label="item.value"
            border
            >{{ item.text }}</el-radio
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { queryPageAddress, addAddress, updateAddress, deleteAddress } from "@/api/companyManage.js";
let ac;
export default {
  name: "addressPage",
  components: {
    commonTable,
    customPopconfirm,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      radioStatusArr: [
        { text: "启用", value: true },
        { text: "禁用", value: false },
      ],
      // 添加公司地址弹框
      bEdit: false,
      centerDialogVisible: false,
      rules: {
        address: [{ required: true, message: "请输入位置", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      ruleForm: {
        addressId: "",
        address: "",
        status: true,
      },
    };
  },
  mounted() {
    this.getTableData();
  },
  beforeDestroy() {
    if (ac) {
      ac.removeEventListener("onconfirm", this.setValue);
    }
  },
  methods: {
    // 请求企业地址
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      queryPageAddress(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || [];
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      this.$nextTick(() => {
        let data = JSON.parse(JSON.stringify(val));
        this.centerDialogVisible = true;
        this.ruleForm = {
          addressId: data.addressId,
          address: data.address,
          status: data.status,
        };
        console.log(this.ruleForm);
      });
    },
    /**@method 添加公司地址 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              ...this.ruleForm,
            };
            if (this.bEdit) {
              await updateAddress(data);
            } else {
              await addAddress(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "添加成功",
              type: "success",
            });
            await this.getTableData();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 企业地址ID
     */
    async del(val) {
      console.log(val);
      try {
        await deleteAddress({ addressId: val });
        await this.getTableData();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: map,
      });
      ac.addEventListener("onconfirm", this.setValue);
    },
    /**@method 模态框关闭的回调 */
    close() {
      this.ruleForm = {
        addressId: "",
        address: "",
        status: true,
      };
      this.bEdit = false;
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.address-view {
  background: #ffffff;
  padding: 24px;
}
</style>
